import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Legal } from '../../layouts/Legal';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <Legal title="Pay Terms">{children}</Legal>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Trybe Pay is our integrated PCI compliant payment solution; enabling Trybe customers to take payments online or over the phone, all through their Trybe software. Guarantee income before the customers steps into your venue.`}</p>
    <p>{`Please see the applicable terms below for the payment system you are using which will be detailed in your standing order.`}</p>
    <h2>{`Online Payment System - Adyen (Trybe Pay)`}</h2>
    <p>{`Customers will need to go through a KYC process as part of onboarding with the Trybe platform and before any payments can be processed. Please contact your account manager to begin.`}</p>
    <p>{`Terminal payment fees are a blended rate of 1.4% + 20p per transaction.`}</p>
    <p>{`All other transaction fees are based on the interchange++ pricing model, which includes interchange fees charged by the card networks, scheme fees, and other applicable fees. Our acquiring fee for each transaction is 1% + 20p subject to the terms and conditions of your contract.`}</p>
    <p>{`This excludes Amex payments which are instead a flat fee of 3% per transaction.`}</p>
    <p>{`Card authentications are charged at 10p per card auth.`}</p>
    <p>{`Completed payments and refunds are added into subsequent payouts which are processed some days in advance subject to your contract's terms and conditions. Payouts are generated daily and all transaction fees are subtracted from the payout amount.`}</p>
    <p>{`Please note that if a chargeback occurs and the money has already been transferred, we will need to claim this back from you along with any incurred Adyen Chargeback fees as per our terms and conditions.`}</p>
    <h2>{`Online Payment System - Stripe Connect`}</h2>
    <p>{`You will need to set-up your own online merchant account through Stripe Connect. We will provide you with the steps required during initial set-up and on-boarding.`}</p>
    <p>{`Within Stripe Connect and Trybe, you get the full back-end customer functionality and reporting to your finance team on the transactions so you can reconcile your online payments.`}</p>
    <p>{`You use your own Stripe account and will pay the fees outlined by Stripe. If you have your own special rates, these will apply to all transactions we process through Stripe on your behalf.`}</p>
    <p>{`Setup requires you to go through the Stripe onboarding process, contact your account manager to begin.`}</p>
    <h2>{`Direct Debit Payment System - Stripe Connect`}</h2>
    <p>{`You will need to set-up your own online merchant account through Stripe Connect. We will provide you with the steps required during initial set-up and on-boarding.`}</p>
    <p>{`There is a £50+vat/month fee in order to give Stripe the capability to take direct debits on your behalf.`}</p>
    <p>{`You can find your Direct Debit transaction fees on your standing order.`}</p>
    <p>{`As it will be Five Nines Digital Limited (our trading company) actually processing the money, we are legally responsible for the transaction. Money will be transferred, in the main in 7 working days. Please note, that if a chargeback occurs and the money has already been transferred we will need to claim this back from you along with any incurred Stripe Chargeback fees.`}</p>
    <p>{`Setup requires you to go through the Stripe onboarding process, contact your account manager to begin.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      